@import '../../App.scss';

.footer {
  background-color: transparent;
  backdrop-filter: blur(10px);
  color: $secondary-color;
  padding: 20px 0;
  margin-top: 60px;

  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;

    @include tablet {
      flex-direction: column;
      align-items: center;
    }

    @include mobile {
      flex-direction:column; 
    }
  }

  .footer-left,
  .footer-right {
    flex: 1;
    margin: 20px 10px;

    @include mobile {
      margin: 10px 0;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 22px;

      @include mobile {
        font-size: 18px;
      }
    }
  }

  .footer-logo {
    max-width: 150px;
    margin-bottom: 15px;

    @include mobile {
      max-width: 100px;
    }
  }

  .footer-address {
    line-height: 1.4;

    a {
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .footer-map {
    margin-top: 20px;

    iframe {
      border: 0;
      width: 100%;
      height: 250px;
      border-radius: 5px;

      @include tablet {
        height: 200px;
      }

      @include mobile {
        height: 150px;
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid $text-color;
    padding-top: 10px;

    a {
      color: $primary-color;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

// Media queries for smaller laptop screens
@media (min-width: 1024px) and (max-width: 1366px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    margin: 15px 5px;
  }

  .footer-map iframe {
    height: 200px;
  }
}

// Media queries for mobile screens
@media (max-width: 480px) {
  .footer-left h3, .footer-right h3 {
    font-size: 18px;
  }

  .footer-map iframe {
    height: 120px;
  }
}
