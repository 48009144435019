@import '../../App.scss';

.cookie-consent {
  position: fixed;
  bottom: 130px;
  left: 20px;
  right: 20px;
  background: $secondary-color;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0 0 10px;
    text-align: center;
    color: darken($primary-color, 15%);
    font-weight: 500;
  }

  .cookie-consent-buttons {
    display: flex;
    gap: 10px;

    button {
      padding: 10px 20px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      background-color: $primary-color;
      color: white;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      &:nth-of-type(2) {
        background-color: #d9534f;

        &:hover {
          background-color: darken(#d9534f, 10%);
        }
      }
    }
  }
}

  