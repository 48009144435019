@import '../../App.scss';

.Presentation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(10px);
  margin: 20rem auto;
  padding: 40px;

  .style {
    color: $primary-color;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .title-Presentation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    position: relative;
    color: $secondary-color;

    &::after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      height: 4px;
      background-color: $primary-color;
    }

    @include mobile {
      flex-direction: column;
      text-align: center;
      gap: 20px;
    }

    @include tablet {
      flex-direction: column;
      text-align: center;
      gap: 20px;
    }

    @include laptop {
      flex-direction: row;
      text-align: left;
      gap: 30px;
    }

    @include desktop {
      flex-direction: row;
      text-align: left;
      gap: 50px;
    }

    h1 {
      font-size: 76px;
      font-weight: 100;
      color: $secondary-color;

      @include mobile {
        font-size: 2rem;
      }

      @include tablet {
        font-size: 3rem;
      }

      @include laptop {
        font-size: 3.5rem;
      }

      @include desktop {
        font-size: 76px;
      }
    }
  }

  h2 {
    color: $primary-color;
    font-size: 2rem;
    font-weight: 500;
    text-decoration: underline;
    margin: 20px 0;

    @include mobile {
      font-size: 1rem;
    }

    @include tablet {
      font-size: 1.5rem;
    }

    @include laptop {
      font-size: 1.8rem;
    }

    @include desktop {
      font-size: 2rem;
    }
  }

  .container {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    max-width: 1266px;
    margin: 1rem auto;

    @include mobile {
      flex-direction: column;
      width: 100%;
    }

    @include tablet {
      flex-direction: column;
      width: 100%;
    }

    @include laptop {
      flex-direction: row;
      width: 100%;
    }

    @include desktop {
      flex-direction: row;
      width: 100%;
    }

    .box-Presentation {
      padding: 20px;
      border: 2px solid $primary-color;
      border-radius: 5px;
      background-color: $background-color;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin: 20px;
      flex: 1;
      transition: transform 0.3s, box-shadow 0.3s;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

      img {
        width: 30%;
        height: auto;
        border-radius: 5px;
        object-fit: cover;
        margin: 20px auto;

        @include mobile {
          width: 70%;
          height: auto;
        }

        @include tablet {
          width: 40%;
          height: auto;
        }

        @include laptop {
          width: 40%;
          height: auto;
        }

        @include desktop {
          width: 30%;
          height: auto;
        }
      }

      p {
        font-size: 20px;
        flex-grow: 1;
        color: $text-color;
        line-height: 1.6;

        @include mobile {
          font-size: 1rem;
        }

        @include tablet {
          font-size: 1.2rem;
        }

        @include laptop {
          font-size: 1.3rem;
        }

        @include desktop {
          font-size: 20px;
        }

        b {
          color: $primary-color;
        }
      }
    }
  }
}

@include tablet {
  .Presentation {
    padding: 20px;
    margin: 25rem auto;

    .title-Presentation {
      h1 {
        font-size: 3rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}

@include mobile {
  .Presentation {
    padding: 10px;
    margin: 15rem auto;

    .title-Presentation {
      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 0.9rem;

        img {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
