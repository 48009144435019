@import '../../App.scss';

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 12%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;

    .logo {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        margin-right: 20px;

        img {
            width: 30%;
            height: auto;
        }
    }

    .navbar {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;

        a {
            position: relative;
            font-size: 18px;
            color: $secondary-color;
            font-weight: 500;
            text-decoration: none;

            &::before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 2px;
                background: $primary-color;
                transition: 0.5s;
            }

            &:hover::before {
                width: 100%;
            }

            &.contact-link {
                background-color: $secondary-color;
                color: $primary-color;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                transition: background-color 0.3s, color 0.3s, transform 0.3s;

                &:hover {
                    background-color: darken($primary-color, 20%);
                    color: lighten($secondary-color, 10%);
                    transform: scale(1.05);
                }
            }
        }
    }

    .burger {
        display: none;
        cursor: pointer;

        div {
            width: 25px;
            height: 3px;
            background-color: #fff;
            margin: 5px;
            transition: all 0.3s ease;
        }
    }

    @include mobile {
        .navbar {
            display: none;
            flex-direction: column;
            width: 50%;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 5px;
            position: absolute;
            padding: 10px;
            top: 70px;
            left: 180px;

            &.open {
                display: flex;
            }

            a {
                margin: 10px 0;
            }
        }

        .burger {
            display: block;
        }
    }

    @include tablet {
        .navbar {
            display: none;
            flex-direction: column;
            width: 95%;
            margin: 0 auto;
            background-color: rgba(0, 0, 0, 0.8);
            position: absolute;
            top: 180px;
            left: 10px;

            &.open {
                display: flex;
            }

            a {
                margin: 10px 0;
            }
        }

        .burger {
            display: block;
        }
    }

    // New media query for intermediate sizes
    @media (min-width: 738px) and (max-width: 1024px) {
        .navbar {
            flex-direction: row;
            width: 80%;
            position: static;
            background-color: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            gap: 5px;

            a {
                font-size: 16px;
                margin: 0 10px;
            }
        }

        .logo img {
            width: 90%;
        }

        .burger {
            display: none;
        }
    }
}