@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import '~leaflet/dist/leaflet.css';

/* Variables de couleurs */
$primary-color: #b78032;
$secondary-color: #fff;
$background-color: #f0f0f0;
$button-color: #b78032;
$text-color: #333;
$error-color: #ff4d4f;
$error-background-color: #fff1f0;
$success-color: #52c41a;
$success-background-color: #f6ffed;


/* Mixins pour les points de rupture */
@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 738px) and (max-width: 1024px) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: 1024px) and (max-width: 1300px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1300px) {
    @content;
  }
}

/* Réinitialisation des styles par défaut et polices */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles globaux */
body {
  background: url('../public/background3.jpg') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
}

/* Styles spécifiques aux points de rupture */
@include mobile {
  body {
    background-size: cover;
  }
}

@include tablet {
  body {
    background-size: cover;
  }
}

/* Liens */
a {
  text-decoration: none;
}
