@import '../../App.scss';

.language-selector {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  gap: 10px;

  button {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: transparent;
    text-indent: -9999px;

    &:hover {
      transform: scale(1.1);
    }

    &.active {
      border-color: red;
    }

    // Assurez-vous que les chemins des images sont corrects et que les images existent
    &.en {
      background-image: url(../../assets/english-flag.webp);
    }

    &.fr {
      background-image: url(../../assets/french-flag.webp);
    }

    &.es {
      background-image: url(../../assets/spanish-flag.webp);
    }
  }

  @include mobile {
    top: 5px;
    right: 5px;
    gap: 5px;

    button {
      width: 20px;
      height: 15px;
    }
  }

  @include tablet {
    top: 5px;
    right: 20px;
    gap: 8px;

    button {
      width: 25px;
      height: 18px;
    }
  }

  @include desktop {
    top: 15px;
    right: 15px;
    gap: 10px;

    button {
      width: 40px;
      height: 35px;
    }
  }
}
